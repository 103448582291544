import "./src/styles/fonts.css";
import "./src/styles/global.css";

export const onInitialClientRender = () => {
  // Get the user's browser language
  const userLanguage =
    window.navigator.language || window.navigator.userLanguage;

  // Use a mapping to match browser language to supported languages
  const currentPath = window.location.pathname;
  // Set the language to the detected language or fallback to the default
  const selectedLanguage = userLanguage?.slice(0, 2) || "en";
  // Redirect to the appropriate language-specific homepage
  if (selectedLanguage === "fr" && !currentPath.startsWith(`/${"fr"}/`)) {
    window.location.href = `/fr${currentPath}`;
  }

  if (!window.swan || !window.swan.isLoaded) {
    window.swan = window.swan || [];
    window.swan.isLoaded = true;
    window.swan.pk = "cm7oxep1q0006lb05bgnalsy8";

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://swan-scripts.s3.amazonaws.com/bundle.js";

    document.head.appendChild(script);
  }
};

export const onRouteUpdate = () => {
  if (typeof window !== "undefined") {
    if (!window.redditPixelLoaded) {
      window.redditPixelLoaded = true;

      (function (w, d) {
        if (!w.rdt) {
          const p = (w.rdt = function () {
            if (p.sendEvent) {
              p.sendEvent.apply(p, arguments);
            } else {
              p.callQueue.push(arguments);
            }
          });
          p.callQueue = [];
          const t = d.createElement("script");
          t.src = "https://www.redditstatic.com/ads/pixel.js";
          t.async = true;
          const s = d.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(t, s);
        }
      })(window, document);

      window.rdt("init", "a2_gbweu16jig4z");
    }

    window.rdt("track", "PageVisit");
  }
};

onInitialClientRender();
